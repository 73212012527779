import {mapActions, mapMutations, mapGetters} from 'vuex';

import {required, email, maxLength,} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import {isHasPermission} from '@/utils/utils';

export default {
  name: 'workers.create',
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        name: '',
        last_name: '',
        email: '',
        phone: '',
        roles: []
      },
      isWorkerCreate: false,
      validationErrors: {},
      filteredRoles: null
    }
  },
  components: {},
  validations: {
    payload: {
      name: {
        required,
        maxLength: maxLength(30),
      },
      last_name: {
        maxLength: maxLength(30),
      },
      email: {
        required,
        email,
        maxLength: maxLength(40),
      },
      phone: {
        required,
        maxLength: maxLength(13),
      },
      roles: {
        required
      },
    }
  },
  created() {
    this.fetchRoles();
  },
  mounted() {},
  computed: {
    ...mapGetters({
      isWorkerCreating: 'administration/isWorkerCreating',
      isInviteLoading: 'auth/isInviteLoading',
      roles: 'roles/roles',
      isRolesLoading: 'roles/isRolesLoading',
    }),
    isDisabled() {
      return this.payload.name === '' ||
        this.payload.email === '' ||
        this.payload.phone === '' ||
        this.payload.roles.length === 0;
    },
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('validationMax',{count: 30}));
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    lastNameErrors() {
      let error = [];
      if (!this.$v.payload.last_name.$dirty) {
        return error;
      }
      if (!this.$v.payload.last_name.maxLength) {
        error.push(this.$t('validationMax',{count: 30}));
      }
      if (this.validationErrors.last_name) {
        this.validationErrors.last_name.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax',{count: 40}));
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    phoneErrors() {
      let error = [];
      if (!this.$v.payload.phone.$dirty) {
        return error;
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.phone.maxLength) {
        error.push(this.$t('validationMax',{count: 13}));
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    rolesErrors() {
      let error = [];
      if (!this.$v.payload.roles.$dirty) {
        return error;
      }
      if (!this.$v.payload.roles.required) {
        error.push(this.$t('validationRequired'));
      }
      if (this.validationErrors.roles) {
        this.validationErrors.roles.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  watch: {
    roles (list) {
      if (list) {
        this.filteredRoles = list.filter(i => {return i.can_delete});
      }
    }
  },
  methods: {
    ...mapActions({
      createWorker: 'administration/ADMINISTRATION_REQUEST_WORKER_CREATE',
      sendInvite: 'auth/AUTH_REQUEST_INVITE',
      fetchRoles: 'roles/ROLES_REQUEST_LIST_GET',
    }),
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.createWorker(this.payload)
            .then(() => {
              this.$toasted.success(this.$t('workerCreated'));
              if (isHasPermission('invites_access')) {
                this.isWorkerCreate = true;
              } else {
                this.$router.push({name: 'workers.list'}).catch(() => {console.log();});
              }
            }).catch(error => {
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
            this.validationErrors = error.response.data.errors
          }
        });
      }
    },
    invite() {
      this.sendInvite({email: this.payload.email})
          .then(() => {
            this.$toasted.success(this.$t('workerInviteSendSuccess'));
            this.$router.push({name: 'workers.list'}).catch(() => {console.log();});
          }).catch(error => {
        if (error.response.status === 422) {
          this.$toasted.error(error.response.data.message);
        }
      })
    },
  }
}
